.App {
  display: flex;
  height: 100vh;
}

.split {
  flex: 1; /* Split equally */
  overflow: auto; /* Add scroll if needed */
}

.navbar {
  padding: 10px;
  background-color: #f0f0f0; /* Example background color */
}

.logo {
  height: 50px; /* Adjust as needed */
}

.dropdown-container {
  margin: 20px;
}

.dropdown-container label {
  display: block;
  margin-top: 10px;
}

.left {
  background-color: #f8f8f8;
}

.right {
  background-color: #e8e8e8;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.field input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 0.5em;
}

.titles-container {
  display: flex; /* This will layout its children (the two divs) in a row */
  justify-content: start; /* This aligns items to the start of the container */
  gap: 10px; /* Optional: adds some space between the two divs */
}


/* override aws-amplify's primary button background color */
.amplify-label {
  font-family: var(--font-primary) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.amplify-input {
  font-family: var(--font-primary) !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.amplify-field-group :not(:last-child) .amplify-input {
  border-end-end-radius: 0 !important;
  border-start-end-radius: 0 !important;
}

.amplify-field-group__outer-end .amplify-field-group__control {
  border-end-end-radius: 8px !important;
  border-start-end-radius: 8px !important;
}

.amplify-button--primary {
  background-color: var(--color-primary) !important;
  border-radius: 10px !important;
  font-family: var(--font-primary) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.amplify-button--link {
  color: var(--color-primary) !important;
  border-radius: 10px !important;
  font-family: var(--font-primary) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.amplify-button--link:hover {
  background-color: var(--color-primary-light) !important;
  border-radius: 10px !important;
}

.amplify-card{
  padding: 0px !important;
  width: 100vw !important;
  height: 100vh !important;
  background-image: url('../public/background.svg') !important;
  background-size: cover !important; /* Ensure the background covers the entire container */
  background-position: center !important; /* Center the image */
  background-repeat: no-repeat !important; /* Prevent the image from repeating */
}

.auth-logo {
  margin-top: 4px;
  width: 146px;
  height: 32px;
  background-image: url('../public/lextext-logo.svg');
  background-size: contain; /* Ensure the entire logo is visible */
  background-repeat: no-repeat; /* Prevent the background from repeating */
  background-position: center; /* Center the logo */
  margin-bottom: 32px;
}

.auth-view {
  text-align: center;
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.amplify-heading--3 {
  /* refer to h1 in design */
  font-family: var(--font-primary) !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important; /* 133.333% */
}

[data-amplify-router] {
  margin-top: 120px !important;
  border-radius: 16px !important;
  border-width: 0px !important;
  box-shadow: none !important;
}

fieldset {
  padding-bottom: 16px !important;
}
/* font styles */
:root {
  --font-primary: "instrument sans", sans-serif;
}

/* color styles */
:root {
  --color-primary: #1861DD;
  --color-primary-hover: #1B4EB2;
  --color-primary-light: #DAEEFF;
  --color-primary-selection: #EFF8FF;
  --color-outline: #E3E8EF;
  --color-font: #363636;
  --overlay-shade1: rgba(0, 0, 0, .60);
  --white: #fff;
  --error: #F04438;
}

/* Main container to center the layout */
.research-container {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  background-color: #F8FAFC;
}

/* Form styling */
.input-form {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  gap: 20px;
  align-items: flex-end;
  border: 1px solid #9AA4B2;
  border-radius: 12px;
  padding: 8px 14px;
  background-color: var(--color-outline);
}

/* Style the button */
.input-form button {
  flex-shrink: 0;
  min-height: 32px;
  min-width: 72px;
}

/* Chat input box styling */
.chat-input {
  flex: 1;
}

/* textarea styling */
textarea {
  background-color: transparent;
}

/* Input re-size styling */
.chat-input.auto-resize {
  min-height: 35px;
  max-height: 240px;
  resize: none; /* Remove the default resize handle */
  overflow-y: auto; /* Hide scrollbar during resize */
  line-height: 22px; 
  box-sizing: border-box;
  width: 100%;
  transition: height 0.1s ease;
  border: none;
  font-family: var(--font-primary);
  font-size: inherit;
  display: flex;
  align-items: center;
  padding: 6px 0px;
}

/* Input focus styling */
.chat-input.auto-resize:focus {
  outline: none; /* Removes the default browser outline */
  border-color: #ccc;
  box-shadow: none;
}

/* Button style from gobal.css */
.button, .file-label {
  /* refer to button-label in design*/
  font-family: var(--font-primary);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: background-color 0.3s ease;
  border-width: 0px;
}

/* component colors */
.button.is-primary, .button.is-link {
  background-color: var(--color-primary);
}

.button.is-primary:hover, .button.is-link:hover {
  background-color: var(--color-primary-hover);
}

.markdown-response {
  color: var(--color-font);
  margin-bottom: 12px;
}

/* Style markdown elements inside the response */
.markdown-response h3 {
  /* refer to h3 in design */
  font-family: var(--font-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding-top: 16px;
  padding-bottom: 4px;
}

.markdown-response strong {
  font-weight: 650;
  color: var(--color-font);
}

.markdown-response strong a, .markdown-response a{
  color: var(--color-primary);
  text-decoration: none;
}

.markdown-response strong a:hover, .markdown-response a:hover{
  color: var(--color-primary-hover);
  text-decoration: underline;
}

.markdown-response ul {
  list-style: disc;
  margin-left: 24px;
  text-indent: 4px;
  padding: 6px 0px;
}

.markdown-response ol {
  margin-left: 24px;
  text-indent: 4px;
  padding: 6px 0px;
}

.markdown-response li {
  padding-bottom: 2px;
}

.markdown-response li:last-child {
  padding-bottom: 0px;
}


/* Container for the user message */
.user-message-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  width: 100%;
}

/* Styling for the chat bubble */
.user-message-bubble {
  background-color: #E3E8EF;
  border-radius: 16px;
  padding: 2px 12px;
  max-width: 70%;
  text-align: left;
  position: relative;
  word-break: break-word;
}

/* Subtle animation for the chat bubble */
@keyframes slideIn {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.user-message-bubble {
  animation: slideIn 0.3s ease-out;
}

/* Results container styling */
.results-container {
  width: 100%;
  max-width: 800px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  flex: 1;
  overflow-y: auto;
  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black 30px,
    black calc(100% - 30px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black 40px,
    black calc(100% - 40px),
    transparent 100%
  );
}

/* Individual result item styling */
.result-item {
  padding: 16px;
}

.result-item p {
  padding: 6px 0;
}

/* Style for when results are empty */
.empty-results {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20vh;
}

/* Center the form when no results */
.centered-form {
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

/* Revert to normal positioning when results exist */
.input-form:not(.centered-form) {
  position: static;
  transform: none;
  max-width: 800px;
}